/** @jsx jsx */
import Article from '../../../template/article'
import { jsx } from '@emotion/core'

const ArticlePage = ({ location }) => (
  <Article location={location}>
    <section>
      <h3>僕はWebの可能性を信じる。</h3>
      <p>
        高校生の時、サマーウォーズという映画を観た。 衝撃だった。僕はそれまでWebの世界でその向こう側にいいる人を意識したことがなかったし 2チャンネルとか掲示板とかもどこか無機質で、書き込みをしてる人は存在していないんじゃないかとすら思っていた。 でもこの映画を観て、「ああ、この向こう側には人がいるんだ」って思えるようになった。どんなに無機質なWebサイトでも運営している人がいて、Webもまた人と人とのつながりなんだって思えた。 ますますWebが好きになった。それと同時に、WebがOZみたいになったら絶対楽しいだろうなって思ったし、絶対そうなっていくなっていくんだって感じた。
      </p>
      <p>
        僕が大学生なる頃、MixiやTwitter、FacebookといったSNSが流行し始め、より沢山の人がWebにアクセスするようになった。僕もTwitterを始めて、大学の友達と授業やアニメの話で盛り上がった。Webと現実世界が密接に繋がり始め、その境界が曖昧になっていくのを感じた。そしてその流れは今なお加速し続けている。
      </p>
      <p>
        僕は近い将来、Webがサマーウォーズの「OZ」みたいな存在になると思っている。半ば確信している。現にGoogleやFacebookのアカウントがあれば様々なのウェブサービスを利用することができ、OZのアバターとさほど変わらないようになってきている。人間の生活はどんどんWebやインターネットに依存する形に変化してきているのだ。
      </p>
      <p>
        人間がWebにアクセスするとき、大抵の場合はウェブブラウザを使用する。これはこれからもそう簡単には変わらないだろう。もしVR/AR デバイスが進化したとしても、必ずそこにはブラウザが搭載され、そのブラウザ経由でWebにアクセスすることになるはずだ。そしてそのウェブブラウザ上で動く唯一の言語がJavaScriptだ。
      </p>
      <p>
        僕はWebの可能性を信じる。Webは世界をもっと良い方向に変えられる力を持っている。その力をうまく伝播させるためには良いユーザーインターフェースが必要だ。そしてWebのインターフェースを作る仕事はJavaScriptにしかできない。それはつまり、JavaScriptは世界を大きく変えることができる可能性を持っていることを示している。と僕は思っている。
      </p>
      <p>
        僕はこれからもJavaScriptを書いていくつもりだし、JavaScriptの力で世界を少し良い方向に変えられたら良いなって思う。
      </p>
    </section>
  </Article>
)

export default ArticlePage
